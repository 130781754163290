import React from "react"
import useStory from "hooks/useStory"
import Page from "components/_common/Page"
import renderBloks from "helpers/renderBloks"

export default function StoryblokEntryTemplate({ pageContext }) {
  const [story, layout] = useStory(pageContext.story, pageContext.layout)

  return (
    <Page
      {...{ story, layout }}
      slug={pageContext.story.slug}
      langLinks={pageContext.langLinks}
    >
      {renderBloks(story.body)}
    </Page>
  )
}
