import { useMemo } from "react"

function prepareStory(story) {
  if (typeof story.content !== "string") {
    throw new TypeError("story.content must be string")
  }

  return JSON.parse(story.content)
}

export default function useStory(...stories) {
  const preparedStories = useMemo(() => {
    if (stories.length === 0) {
      return null
    } else if (stories.length > 1) {
      return stories.map(story => prepareStory(story))
    }
    return prepareStory(stories[0])
  }, [stories])

  return preparedStories
}
